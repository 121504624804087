import React, { useState, useEffect, useCallback } from "react";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import FullPageSpinner from "./components/FullPageSpinner";
import { showCurrentUser } from "./api/users";
import store from "./redux/store";
import Actions from "./redux/actions";
import { log_error, getAuthorizationToken } from "./api";
import { authorizeClientId } from "./api/authentication";

const AppLoader = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 991px)",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthanticated, setIsAuthanticated] = useState(false);
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const client_id = params.get("client_id");
  const utm_source = params.get("utm_source");
  store.dispatch(Actions.setUtmSource(utm_source));

  useEffect(() => {
    const utms = JSON.parse(localStorage.getItem("utms") || "{}");
    const newUtms = {};
    newUtms.utm_source = params.get("utm_source");
    newUtms.utm_medium = params.get("utm_medium");
    newUtms.utm_campaign = params.get("utm_campaign");
    newUtms.utm_term = params.get("utm_term");
    newUtms.utm_content = params.get("utm_content");
    for (const [key, value] of Object.entries(newUtms)) {
      if (value) {
        utms[key] = value;
      }
    }
    localStorage.setItem("utms", JSON.stringify(utms));
  }, [search]);

  useEffect(() => {
    (async () => {
      try {
        await loadCurrentUser();
        const token = getAuthorizationToken();
        if (pathname === "/login" && !!token && client_id) {
          try {
            const { status, data: { code } = {} } = await authorizeClientId(
              client_id
            );
            const redirectUrl = `${process.env.REACT_APP_DISTRO_REDIRECT_URL}?code=${code}`;
            console.log("redirectUrl", redirectUrl);
            if (status === 200 && !!code) {
              return (window.location.href = encodeURI(redirectUrl));
            }
          } catch {
            return (window.location.href = "/join");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const Onboarding = Loadable({
    loader: () => import("./Onboarding"),
    loading: FullPageSpinner,
  });
  const App = Loadable({
    loader: () => import("./App"),
    loading: FullPageSpinner,
  });
  const AppMobile = Loadable({
    loader: () => import("./AppMobile"),
    loading: FullPageSpinner,
  });

  const QueueManager = Loadable({
    loader: () => import("./QueueManager"),
    loading: FullPageSpinner,
  });

  const loadCurrentUser = async () => {
    if (!getAuthorizationToken()) return;
    try {
      let {
        data: { user, meta },
      } = await showCurrentUser();
      store.dispatch(Actions.setCurrentUser(user, meta));
      setIsAuthanticated(true);
    } catch (error) {
      console.log(log_error(error));
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        localStorage.removeItem("Authorization");
        localStorage.removeItem("sb_token");
        localStorage.removeItem("comments_before_login");
      }
    }
  };

  const slashOrAuthRoute = useCallback(
    () =>
      pathname === "/" ||
      pathname === "/login" ||
      pathname === "/signup" ||
      pathname === "/about" ||
      pathname === "/forgot_password" ||
      pathname.includes("/reset_password"),
    [pathname]
  );

  return (
    <Provider store={store}>
      {isLoading ? (
        <FullPageSpinner />
      ) : !isAuthanticated && slashOrAuthRoute() ? (
        <Onboarding />
      ) : (
        <>
          <QueueManager />
          {isMobileDevice ? <AppMobile /> : <App />}
        </>
      )}
    </Provider>
  );
};

export default AppLoader;
